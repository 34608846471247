/* Width of emoji used by user to display status. */
$user_status_emoji_width: 24px;

.right-sidebar {
    & a:hover {
        text-decoration: none;
    }
}

.right-sidebar-title {
    color: var(--color-text-sidebar-heading);
    font-size: inherit;
    font-weight: normal;
    display: inline;
}

#buddy_list_wrapper {
    position: relative;
    margin-left: 0;
    overflow: auto;
}

.toggle-narrow-users,
.toggle-other-users {
    width: 7px;
}

#buddy-list-users-matching-view-container,
#buddy-list-other-users-container {
    margin-bottom: 10px;

    &.no-display {
        display: none;
    }

    .view-all-subscribers-link,
    .view-all-users-link {
        margin-left: 15px;
    }
}

#buddy-list-users-matching-view-container.collapsed {
    #buddy-list-users-matching-view,
    .view-all-subscribers-link {
        display: none;
    }
}

#buddy-list-other-users-container.collapsed {
    #buddy-list-other-users,
    .view-all-users-link {
        display: none;
    }
}

#buddy-list-users-matching-view,
#buddy-list-other-users {
    width: 90%;
    margin-left: 10px;
    margin-bottom: 0;
    overflow-x: hidden;
    list-style-position: inside; /* Draw the bullets inside our box */
    line-height: var(--line-height-sidebar-row);

    & li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        list-style-type: none;
        border-radius: 4px;
        padding: 0;

        .user-list-sidebar-menu-icon {
            visibility: hidden;
            justify-self: center;

            & i {
                font-size: 17px;
                /* Ensure the icon takes the same baseline
                   as the rest of the row, for perfect
                   vertical alignment with the username and
                   status circle. */
                line-height: inherit;
            }

            /*
            Hover does not work for touch-based devices like mobile phones.
            Hence the icons does not appear, making the user unaware of its
            presence on such devices. The following media property displays the
            icon by default for such behaviour.
            */

            @media (hover: none) {
                visibility: visible;
                /* Show dots on touchscreens in a less distracting,
                   lighter shade. */
                color: var(--color-vdots-hint);
            }
        }

        &:hover {
            .user-list-sidebar-menu-icon {
                visibility: visible;
                cursor: pointer;
                color: var(--color-vdots-visible);

                &:hover {
                    color: var(--color-vdots-hover);
                }
            }
        }

        &:hover,
        &.highlighted_user {
            background-color: var(--color-buddy-list-highlighted-user);
        }
    }

    .user_circle {
        width: var(--length-user-status-circle);
        height: var(--length-user-status-circle);
        grid-area: starting-anchor-element;
        place-self: center center;

        @media screen and (resolution <= 1x) {
            /* User circles appear to sag a bit on
               low-resolution screens, so this fix
               targets them without disrupting
               line-height values. */
            margin-top: -2.5px;
        }
    }

    .empty-list-message {
        font-style: italic;
        color: var(--color-text-empty-list-message);
        /* Overwrite default empty list font size, to look better under the subheaders. */
        font-size: 14px;
        /* Override .empty-list-message !important styling */
        padding: 0 !important;
        margin-left: 5px;
        text-align: left;

        &:hover {
            background-color: inherit;
        }
    }

    /* Overwrite some stray list rules (including one in left_sidebar.css) to turn color
       back to the bootstrap default. */
    .view-all-subscribers-link,
    .view-all-users-link {
        color: var(--color-text-url);

        &:hover {
            color: var(--color-text-url-hover);
        }
    }

    & a {
        color: inherit;
        margin-left: 0;
    }
}

.buddy-list-subsection-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--color-background);
    line-height: 1;
    position: sticky;
    top: 0;
    z-index: 1;
    color: var(--color-text-default);
}

.buddy-list-heading {
    user-select: none;
    font-weight: 600;
    margin: 0;
    padding: 5px;
}

.buddy-list-subsection-header.no-display {
    display: none;
}

.user-presence-link,
.user_sidebar_entry .selectable_sidebar_block {
    overflow: hidden;

    .user-name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.user_sidebar_entry .selectable_sidebar_block {
    grid-area: row-content;
    display: grid;
    grid-template:
        "starting-anchor-element row-content markers-and-controls" var(
            --line-height-sidebar-row
        )
        ".                       row-content ." auto / 20px minmax(0, 1fr)
        minmax(0, auto);
    align-items: baseline;
}

.user-presence-link {
    grid-area: row-content;
}

.my_user_status {
    opacity: 0.5;
    white-space: nowrap;
}

.selectable_sidebar_block {
    cursor: pointer;
}

.user_list_style_values {
    .user-name-and-status-emoji {
        display: flex;
        align-items: center;
        width: 100%;

        .user-name {
            display: inline-block;
            max-width: calc(100% - $user_status_emoji_width);
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        .status-emoji {
            line-height: 20px;
            margin-left: 6px;
        }
    }

    .status-text {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

.user_sidebar_entry {
    display: grid;
    /* We establish a two-row, two-column outer grid so
       that controls remain aligned with the username,
       even when there is a status line shown below.

       The 26px column for the vdots is less than the
       30px allotted in the left sidebar, but it holds
       the username area constant, so that no ellipsis
       appears on the username on hover. */
    grid-template:
        "row-content ending-anchor-element" var(--line-height-sidebar-row)
        "row-content ." auto / minmax(0, 1fr) 26px;
    align-content: baseline;

    .user-name-and-status-emoji {
        display: flex;
    }

    .status-text {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.75;
        font-size: 90%;
    }

    & span.status-text:not(:empty) {
        /* Cinch up the status text by one quarter of the
           sidebar row's line-height. */
        margin-top: calc((var(--line-height-sidebar-row) / 4) * -1);
    }

    .unread_count {
        grid-area: markers-and-controls;
        align-self: center;
        display: none;
    }

    .unread_count:not(.hide) {
        display: block;
        margin-left: 4px;

        &:empty {
            /* Hide otherwise empty unread count pill
               after DMs have been read, but before the
               count has been removed from the DOM. */
            display: none;
        }
    }
}

#userlist-toggle {
    text-align: center;
    vertical-align: middle;
}

#userlist-toggle-button {
    text-decoration: none;
    color: hsl(0deg 0% 60%);

    &:hover {
        color: inherit;
    }
}

.right-sidebar-items:first-of-type #userlist-header {
    border-top: none;
}

#userlist-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;

    #userlist-title {
        margin: 0;
    }

    #user_filter_icon {
        opacity: 0.5;
        margin-right: 5px;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }

    /* hovering over the userlist-header creates the same highlight effect as hovering over the user_filter_icon */
    &:hover > #user_filter_icon {
        opacity: 1;
        cursor: pointer;
    }
}

.right-sidebar-shortcuts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* The margin top is calculated from a legacy 25px height,
       from a 20px line of text and 5px of margin top. We calculate
       a scaling margin-top by subtracting the em-unit line height
       from the legacy value. */
    margin-top: calc(25px - (var(--legacy-body-line-height-unitless) * 1em));
}

#user_search_section {
    white-space: nowrap;
    margin-bottom: 10px;

    & input {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 20px;
        width: calc(100% - 40px - 3px);
    }

    .clear_search_button {
        margin-left: -1px;
    }
}
